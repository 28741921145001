<template>
	<div class="divide-y bg-white">
		<div v-for="item in items" :key="item" class="flex flex-col space-y-2 mb-6 md:p-8">
			<div v-if="item.header || item.description" class="px-4 flex justify-between sm:px-0 pt-4 sm:pt-0">
				<div>
					<Text size="2xl" weight="bold" color="gray-900" class="flex" :content="item.header" />
					<Text size="sm" color="gray-700" :content="item.description" class="flex mt-2" />
				</div>
				<div class="flex mt-4 mb-4">
					<Icon :name="item.iconName" color="primary-900" :isOutline="true" />
					<Text @click="$emit('onClickEditScript')" :content="item.iconText" color="primary-900" class="ml-2 underline"
						hoverColor="primary-900" size="sm" weight="medium" />
				</div>
			</div>
			<div>
				<Simple v-if="item?.tableList?.length > 0" :list="item.tableList" class="flex h-auto overflow-x-auto sm:block" :displayHeaders="item.displayHeaders"
					:displayTableOnly="item.displayTableOnly">
					<template v-slot:multiselect="{ id, selectedItem, setSelectedItem }">
						<div v-if="id !== selectedItem" class="w-full flex justify-center space-x-2 items-center h-full bg-white">
							<Text @click="() => $emit('onEdit', item)" class="underline cursor-pointer" :content="'View'"
								color="indigo-500" hoverColor="indigo-600" size="sm" weight="medium" />
							<Text @click="() => $emit('onDelete', item)" class="underline cursor-pointer" :content="'Remove'"
								color="red-500" hoverColor="red-600" size="sm" weight="medium" />
						</div>
						<div v-else class="flex items-center w-full space-x-2">
							<MultiSelectDropdown :options="item.multiSelectDropdown" class="w-full" />
							<Icon name="XIcon" size="5" color="gray-500" hoverColor="gray-300" customClass="mt-2 cursor-pointer"
								@click="setSelectedItem(null)" />
						</div>
					</template>
				</Simple>
				<!-- <Text v-if="!isOpen" content="Link or Add New" color="primary-600" class="flex mt-4 ml-4 sm:ml-0 underline cursor-pointer"
					hoverColor="primary-900" size="sm" weight="medium" @click="isOpen = true" /> -->
				<Button v-if="!isOpen && get(item, 'tableList.length', 0) > 0" :content="btnLabel" variant="primary" @click="isOpen = true" class="mt-4"></Button>
				<div v-else-if="isOpen && get(item, 'tableList.length', 0) > 0" class="flex items-center w-full space-x-2">
					<MultiSelectDropdown :options="item.multiSelectDropdown" class="w-full " isObj
						@update:selected="items => $emit('onUpdateOptions', items)" isNotAdd />
					<Icon name="XIcon" size="5" color="gray-500" hoverColor="gray-300" customClass="mt-2 cursor-pointer"
						@click="isOpen = false" />
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import Button from '../../atoms/Button/Button.vue'
import MultiSelectDropdown from '../../molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown.vue'
import Link from '../../atoms/Link/Link.vue'
import Icon from '../../atoms/Icons/Icons.vue'
import Text from '../../atoms/Text/Text.vue'
import Simple from '../Tables/Simple/Simple.vue'
import {get} from 'lodash'
/**
 * - Use it to show a LinkedRecordList
 */
export default {
	components: {
		Button, Link, Text, Simple, Icon, MultiSelectDropdown
	},
	data() {
		return {
			isOpen: false,
			selectedValue: [],
			get,
		}
	},
	props: {
		/**
		 * Use it for LinkedRecordList items
		 */
		items: {
			type: Array,
			default: () => []
		},
		alertModal: {
			type: Object,
			default: () => { }
		},
		btnLabel: {
			type: String,
			default: 'Link/Add New'
		}
	},
}
</script>
