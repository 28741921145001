<template>
    <div class="pb-20">
        <DetailViewHeader
            :title="opportunity?.name"
            :options="['Archive']"
            :backUrl="'/opportunities'"
            :backLabel="'Opportunities'"
            :onBack="() => $router.go(-1)"
            :actionUrl="''"
            :actionLabel="''"
            :isActive="true"
            customClass="mx-2 sm:mx-6"
            class="mt-4"
            @onClickItem="onClickMenu"
            :badgeText="convertEnumToRead(opportunity?.type)"
        />
        <Loader v-if="isPageLoading" :isLoading="isPageLoading" />
        <div v-else>
            <SubNavigationBar
                @onSelect="(item) => (selectedTab = item)"
                :hide-icon="false"
                :tabs="getTabItems()"
                customClass="mt-4 mx-0 sm:mx-8"
            />
            <div class="space-y-10" v-if="selectedTab.name === 'Details'">
                <div
                    v-if="surveyData?.length > 0"
                    class="sm:mx-8 grid grid-cols-1 lg:grid-cols-7 gap-x-7 pt-10"
                >
                    <div class="col-span-2">
                        <div class="flex justify-between items-center">
                            <Text
                                size="xl"
                                weight="semibold"
                                color="gray-900"
                                custom-class="md:font-bold"
                                :content="'Survey Summary'"
                            />
                        </div>
                        <div>
                            <p class="leading-4 mt-4">
                                <Text
                                    size="xs"
                                    color="black"
                                    weight="base"
                                    :content="`This is a breakdown of the data that will give you insights into how motivated your potential customers are and how big the market for a solution actually might be.`"
                                    element="span"
                                />
                            </p>
                        </div>
                    </div>
                    <div class="col-span-5">
                        <div class="h-fit rounded-t-md">
                            <OpportunityDataDetail
                                v-bind="{ ...opportunityData }"
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="sm:mx-8 grid grid-cols-1 lg:grid-cols-7 gap-x-7 pt-10"
                >
                    <div class="col-span-2">
                        <div class="flex justify-between items-center">
                            <Text
                                size="xl"
                                weight="semibold"
                                color="gray-900"
                                custom-class="md:font-bold"
                                :content="'Opportunity Details'"
                            />
                        </div>
                        <div>
                            <p class="leading-4 mt-4">
                                <Text
                                    size="xs"
                                    color="black"
                                    weight="base"
                                    :content="`Opportunities are things that people do. Add things like problems and solutions to bring this to life.`"
                                    element="span"
                                />
                            </p>
                        </div>
                    </div>
                    <div class="col-span-5">
                        <div class="h-fit rounded-t-md">
                            <div
                                class="px-5 py-8 grid grid-cols-2 gap-x-4 gap-y-5 bg-white border-t border-l border-r border-gray-300 rounded-t-md"
                            >
                                <div
                                    v-for="field in opportunityDetailForm[0]
                                        .fields"
                                    :key="field.id"
                                    :class="`col-span-${field.cols}`"
                                >
                                    <Input
                                        v-if="
                                            field.inputType === 'TEXT' ||
                                            field.inputType === 'DATE' ||
                                            field.inputType === 'NUMBER'
                                        "
                                        :label="field.label"
                                        :type="field.inputType.toLowerCase()"
                                        :placeholder="field.placeholder"
                                        :helpText="field.helpText"
                                        v-model:value="
                                            formData[field.modelField]
                                        "
                                    />
                                    <InputWithAddon
                                        v-if="
                                            field.inputType ===
                                            'TEXT_WITH_ADDON'
                                        "
                                        v-model:value="
                                            formData[field.modelField]
                                        "
                                        :addon="field.addon"
                                        :label="field.label"
                                        type="text"
                                        :placeholder="field.placeholder"
                                    />
                                    <InputWithAddon
                                        v-if="
                                            field.inputType === 'NUMBER_ADDON'
                                        "
                                        type="number"
                                        :label="field.label"
                                        :type="field.inputType.toLowerCase()"
                                        :placeholder="field.placeholder"
                                        :helpText="field.helpText"
                                        v-model:value="
                                            formData[field.modelField]
                                        "
                                        leading
                                    >
                                        <template #addon>
                                            <div
                                                class="flex items-center space-x-1"
                                            >
                                                <select
                                                    class="outline-none border-none bg-gray-100 text-sm"
                                                    v-model="
                                                        formData[
                                                            'frequencyUnit'
                                                        ]
                                                    "
                                                    placeholder="Select an unit"
                                                >
                                                    <option
                                                        v-for="option in frequencyOptions"
                                                        :key="option.id"
                                                        :value="option"
                                                    >
                                                        {{ option.name }}
                                                    </option>
                                                </select>
                                                <Icon
                                                    name="SelectorIcon"
                                                    color="gray-400"
                                                    size="5"
                                                />
                                            </div>
                                        </template>
                                    </InputWithAddon>
                                    <Textarea
                                        v-else-if="
                                            field.inputType === 'TEXTAREA'
                                        "
                                        :label="field.label"
                                        :type="field.inputType.toLowerCase()"
                                        :placeholder="field.placeholder"
                                        v-model:value="
                                            formData[field.modelField]
                                        "
                                        :helpText="field.helpText"
                                    />
                                    <Select
                                        v-else-if="
                                            field.inputType === 'DROPDOWN'
                                        "
                                        :label="field.label"
                                        :placeholder="field.placeholder"
                                        v-model:selected-item="
                                            formData[field.modelField]
                                        "
                                        :items="JSON.parse(field.options)"
                                        :disabled="field.disabled"
                                        :helpText="field.helpText"
                                    />
                                    <AutoComplete
                                        v-else-if="
                                            field.inputType === 'AUTOCOMPLETE'
                                        "
                                        :label="field.label"
                                        :listData="JSON.parse(field.options)"
                                        :placeholder="`${
                                            field.placeholder
                                                ? field.placeholder
                                                : 'Start typing to filter'
                                        }`"
                                        v-model:selectedValue="
                                            formData[field.modelField]
                                        "
                                        :helpText="field.helpText"
                                    />
                                    <template
                                        v-else-if="field.inputType === 'TABLE'"
                                    >
                                        <Text
                                            :content="field.label"
                                            weight="medium"
                                        />
                                        <Text
                                            v-if="field.helpText"
                                            size="xs"
                                            color="gray-400"
                                            custom-class="mt-1 italic"
                                            weight="normal"
                                            :content="field.helpText"
                                        />
                                        <Table
                                            :list="field.list"
                                            :display-headers="false"
                                            :link-text="'Remove'"
                                            @onClickLink="
                                                (item, id) =>
                                                    onRemoveEconomy(
                                                        field,
                                                        id,
                                                        item
                                                    )
                                            "
                                            class="mt-2"
                                        />
                                        <div class="relative mt-4">
                                            <Text
                                                @click.stop="
                                                    isOpenProblems = true
                                                "
                                                size="sm"
                                                weight="medium"
                                                color="indigo-700"
                                                content="Select More"
                                                decoration="underline"
                                                custom-class="cursor-pointer"
                                            />
                                            <div
                                                v-if="isOpenProblems"
                                                v-click-outside="
                                                    () =>
                                                        (isOpenProblems = false)
                                                "
                                                class="py-1 shadow-lg absolute bg-white z-50 left-24 top-0 border overflow-auto rounded-md"
                                                style="
                                                    min-width: 150px;
                                                    max-height: 200px;
                                                "
                                            >
                                                <div
                                                    @click="
                                                        () =>
                                                            onClickEconomy(
                                                                field,
                                                                item
                                                            )
                                                    "
                                                    class="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                                                    v-for="item in getEconomies(
                                                        field.list
                                                    )"
                                                    :key="item.id"
                                                >
                                                    <Text
                                                        size="sm"
                                                        color="black"
                                                        :content="item.problem"
                                                    ></Text>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div
                                class="flex flex-row justify-between w-full bg-white border-l border-r border-t border-gray-300"
                            >
                                <div />
                                <div
                                    class="flex flex-row items-end justify-end w-1/4 my-4 mr-4 sm:mr-3 lg:mr-5"
                                >
                                    <Button
                                        @click="onCancel"
                                        content="Cancel"
                                        :variant="null"
                                        customClass="bg-transparent text-black underline px-2"
                                    />
                                    <Button
                                        @click="onSave"
                                        content="Save"
                                        variant="primary"
                                        customClass="ml-4 px-4"
                                        :isLoading="isLoading"
                                    />
                                </div>
                            </div>
                            <div
                                class="bg-gray-100 rounded-b-none sm:rounded-b-md"
                            >
                                <AccordionWithList
                                    hideBottom
                                    :accordion="accordion"
                                    custom-class="shadow-none border-l border-r border-b border-t-none rounded-b-md"
                                    :bg-color="'gray-100'"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-for="(item, i) in detailLinkedItems"
                    :key="i"
                    class="sm:mx-8 grid grid-cols-1 lg:grid-cols-7 gap-x-7 pt-10"
                >
                    <div class="col-span-2">
                        <div class="flex justify-between items-center">
                            <Text
                                size="xl"
                                weight="semibold"
                                color="gray-900"
                                custom-class="md:font-bold"
                                :content="item?.header"
                            />
                        </div>
                        <p class="leading-4 mt-4">
                            <Text
                                size="xs"
                                color="black"
                                weight="base"
                                :content="item?.description"
                                element="span"
                            />
                        </p>
                        <div class="flex mt-4 mb-4">
                            <Icon
                                :name="item.iconName"
                                color="primary-900"
                                :isOutline="true"
                            />
                            <Text
                                @click=""
                                :content="item.iconText"
                                color="primary-900"
                                class="ml-2 underline"
                                hoverColor="primary-900"
                                size="sm"
                                weight="medium"
                            />
                        </div>
                    </div>
                    <div class="col-span-5">
                        <div class="bg-white divide-y px-2 rounded-md">
                            <div
                                v-for="(option, id) in item.tableList"
                                :key="id"
                                class="px-4 py-3 grid grid-cols-5"
                            >
                                <div class="col-span-2">
                                    <Text
                                        size="sm"
                                        color="black"
                                        weight="semibold"
                                        :content="option?.name"
                                    />
                                </div>
                                <div class="col-span-2">
                                    <Text
                                        size="sm"
                                        color="black"
                                        weight="semibold"
                                        :content="option?.desc"
                                    />
                                </div>
                                <div class="col-span-1">
                                    <div
                                        class="w-full flex justify-end items-center h-full bg-white"
                                    >
                                        <Text
                                            @click="
                                                () => {
                                                    editItem(id, item);
                                                }
                                            "
                                            class="underline cursor-pointer mr-5"
                                            :content="'view'"
                                            color="gray-500"
                                            hoverColor="gray-600"
                                            size="sm"
                                            weight="medium"
                                        />
                                        <Text
                                            @click="
                                                () => {
                                                    removeItem(id, item);
                                                }
                                            "
                                            class="underline cursor-pointer"
                                            :content="'remove'"
                                            color="red-500"
                                            hoverColor="red-600"
                                            size="sm"
                                            weight="medium"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="relative">
                            <Text
                                content="Link or Add New"
                                color="primary-600"
                                class="flex mt-4 ml-4 sm:ml-0 underline cursor-pointer"
                                hoverColor="primary-900"
                                size="sm"
                                weight="medium"
                                @click="
                                    () => {
                                        isShowOpportunitySelect = true;
                                        selectedItem = item;
                                    }
                                "
                            />
                            <div
                                v-if="
                                    isShowOpportunitySelect &&
                                    selectedItem?.header === item?.header
                                "
                                v-click-outside="
                                    () => {
                                        isShowOpportunitySelect = false;
                                        selectedItem = null;
                                        selectedOpportunities = [];
                                    }
                                "
                            >
                                <OpportunityList
                                    :selectedOpportunities="
                                        selectedOpportunities
                                    "
                                    @onClickItem="
                                        (opportunity) =>
                                            onSelectOpportunity(opportunity)
                                    "
                                    :opportunities="getOpportunities(item, i)"
                                    @onAdd="
                                        (searchText) =>
                                            onAddOpportunities({
                                                ...item,
                                                searchText,
                                            })
                                    "
                                    :isLoading="isLoading"
                                    :customClass="'left-0 top-6'"
                                    @onDone="
                                        () =>
                                            onAddOpportunities({
                                                ...item,
                                                searchText: null,
                                            })
                                    "
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="mt-4 mx-0 sm:mx-8"
                v-else-if="selectedTab.name === 'Interviews'"
            >
                <LinkedRecordList
                    :items="linkedItems"
                    btnLabel="+ Linked Interview"
                    @onUpdateOptions="onUpdate"
                    @onClickEditScript="
                        $router.push({
                            name: 'Venture Target Customer Detail',
                            params: {
                                ventureId: $route.params.ventureId,
                                id: targetCustomerUser?.id,
                            },
                            query: { tab: 'Interview' },
                        })
                    "
                />
            </div>
            <div class="mt-4 mx-0 sm:mx-8 gap-4" v-else>
                <EmptyState
                    v-if="surveys?.length === 0"
                    class="bg-white"
                    isFull
                    :icon="null"
                    :title="null"
                    description="This opportunity doesn't have any survey responses yet. When they respond to your survey, each response will show up here."
                />
                <FilterTable
                    :list="surveys"
                    isRow
                    :headers="headers"
                    @onClickItem="(item) => onClickUserSurvey(item)"
                />
            </div>
            <DeleteModal
                :isShow="isOpenRemove"
                @onClose="() => (isOpenRemove = false)"
                :title="`Delete this <b>Opportunity</b>?`"
                :description="`Are you sure you want to delete this <b>Opportunity</b>? They will remove it from the <b>Target Customer</b> and unlink it from other <b>Opportunities, Interviews,</b> and <b>Surveys</b>.`"
                yes-btn-text="Archive"
                no-btn-text="Cancel"
                icon="TrashIcon"
                yesBtnVariant="danger"
                noBtnVariant="secondary"
                :on-no-click="() => (isOpenRemove = false)"
                :on-yes-click="() => onRemoveOpportunity()"
            />
            <SurveyResponseDetail
                @onClose="() => (isShowDetail = false)"
                :isShow="isShowDetail"
                :title="get(selectedUserSurvey, 'opportunity.name')"
                :opportunity="get(selectedUserSurvey, 'opportunity')"
                :userSurvey="selectedUserSurvey"
                @goDetail="
                    () => {
                        isShowDetail = false;
                        selectedTab = getTabItems[0];
                    }
                "
            />
        </div>
    </div>
</template>

<script>
import ListViewItem from "@/components/organisms/ListViewItem/ListViewItem";
import DetailViewHeader from "@/components/molecules/DetailViewHeader/DetailViewHeader";
import SubNavigationBar from "@/components/molecules/SubnavigationBar/SubnavigationBar.vue";
import { c } from "@/components/constants";
import { opportunityDetailForm } from "../../.storybook/sampleData";
import Button from "@/components/atoms/Button/Button";
import Input from "@/components/molecules/Inputs/Component/Component";
import InputWithAddon from "@/components/molecules/Inputs/WithAddon/WithAddon.vue";
import Select from "@/components/molecules/Selects/Component/Component";
import Textarea from "@/components/molecules/Textareas/Simple/Simple";
import AutoComplete from "@/components/molecules/AutoComplete/AutoComplete";
import ContactOutreachActions from "@/components/molecules/ContactOutreachActions/ContactOutreachActions";
import Text from "@/components/atoms/Text/Text";
import Icon from "@/components/atoms/Icons/Icons";
import AccordionWithList from "@/components/molecules/Accordions/AccordionWithList/AccordionWithList";
import Table from "@/components/organisms/Tables/Simple/Simple";
import LinkedRecordList from "@/components/organisms/LinkedRecordList/LinkedRecordList";
import OpportunityDataDetail from "@/components/organisms/OpportunityDataDetail/OpportunityDataDetail";
import moment from "moment";
import DeleteModal from "@/components/organisms/Modals/SimpleAlert/SimpleAlert";
import OpportunityList from "@/components/organisms/Modals/OpportunityList/OpportunityList";
import vClickOutside from "click-outside-vue3";
import { convertEnumToRead } from "@/utils/utils.js";
import { upperFirst, groupBy } from "lodash";
import SurveyResponseDetail from "../components/organisms/Modals/SurveyResponseDetail/SurveyResponseDetail.vue";
import EmptyState from "@/components/organisms/EmptyState/Simple/Simple.vue";
import Header from "@/components/molecules/ListViewHeader/WithFilter/ListViewHeader.vue";
import FilterTable from "@/components/organisms/Tables/WithSortAndFilter/WithSortAndFilter.vue";
import { get } from "lodash";
import Loader from "../components/atoms/Loader/Loader.vue";

export default {
    components: {
        OpportunityDataDetail,
        LinkedRecordList,
        ListViewItem,
        DetailViewHeader,
        SubNavigationBar,
        Button,
        Input,
        Select,
        Textarea,
        AutoComplete,
        ContactOutreachActions,
        Text,
        Icon,
        AccordionWithList,
        OpportunityList,
        Table,
        DeleteModal,
        InputWithAddon,
        EmptyState,
        SurveyResponseDetail,
        Header,
        FilterTable,
        Loader,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    data() {
        return {
            isOpenProblems: false,
            detailViewHeader: c.detailViewHeader,
            formData: {},
            selectedTab: null,
            linkedItems: [
                {
                    header: "Linked Interviews",
                    description:
                        "These are the contacts you have interviewed and linked this opportunity to which creates the Occurrences score.",
                    tableList: [],
                    iconText: "Edit Script",
                    iconName: "MapIcon",
                    displayTableOnly: true,
                    displayHeaders: true,
                    multiSelectDropdown: [],
                },
            ],
            surveys: [],
            surveysData: [],
            isShowDetail: false,
            opportunityData: c.opportunityDataDetail,
            opportunityDetailForm,
            detailLinkedItems: [
                {
                    header: "Motivators (Why?)",
                    description:
                        "Why do people do this? Do they have any higher-level motivations?",
                    tableList: [],
                    iconText: "View Map",
                    iconName: "MapIcon",
                    multiSelectDropdown: [],
                },
                {
                    header: "Solutions and Steps (How?)",
                    description:
                        "How exactly do they do this? You can either list out existing solutions or break it down into more detailed steps of a process",
                    tableList: [],
                    iconText: "View Map",
                    iconName: "MapIcon",
                    multiSelectDropdown: [],
                },
            ],
            accordion: null,
            opportunity: null,
            isLoading: false,
            surveyData: [],
            isOpenRemove: false,
            targetCustomerUser: null,
            venture: null,
            interviewsList: [],
            convertEnumToRead,
            frequencyOptions: c.frequencyOptions,
            selectedItem: null,
            isShowOpportunitySelect: false,
            selectedOpportunities: [],
            headers: null,
            get,
            isPageLoading: false,
        };
    },
    watch: {
        "$route.params": async function (val) {
            console.log(val, "val");
            await this.onInitialize();
        },
    },
    methods: {
        onClickUserSurvey(item) {
            console.log(item);
            this.selectedUserSurvey = this.surveysData?.find(
                (s) => s?.id === item?.id
            );
            this.selectedUserSurvey = {
                ...this.selectedUserSurvey,
                opportunity: {
                    ...this.selectedUserSurvey.opportunity,
                    magnitudeScore: this.selectedUserSurvey?.magnitude,
                    annualizedMagnitude:
                        this.selectedUserSurvey?.annualizedMagnitude,
                    desireScore: this.selectedUserSurvey?.desire,
                    experience: this.selectedUserSurvey?.experience,
                    frequency: this.selectedUserSurvey?.frequency,
                    isActionDone: this.selectedUserSurvey?.isActionDone,
                },
            };
            this.isShowDetail = true;
        },
        getOpportunities(item, i) {
            const data = this.targetCustomerUser?.opportunities?.filter(
                (op) =>
                    op?.isArchived != true &&
                    ![
                        ...this.detailLinkedItems[0]?.tableList,
                        ...this.detailLinkedItems[1]?.tableList,
                        {
                            id: {
                                parent: this.opportunity,
                                child: this.opportunity,
                            },
                        },
                    ]?.find(
                        (o) =>
                            o?.id?.parent?.id === op?.id ||
                            o?.id?.child?.id === op?.id
                    )
            );
            if (item?.searchText) {
                return data?.filter((d) =>
                    d?.name
                        ?.toLowerCase()
                        ?.includes(item?.searchText?.toLowerCase())
                );
            } else {
                return data;
            }
        },
        async onAddOpportunities(item) {
            if (this.isLoading) return false;
            try {
                this.isLoading = true;
                let params = {
                    where: { id: this.opportunity?.id },
                    data: {
                        parentOpportunityLinks: {
                            connect: [],
                        },
                        childOpportunityLinks: {
                            connect: [],
                        },
                    },
                };
                const targetCustomerUserOpportunities = [];
                if (item?.header?.includes("Motivators")) {
                    let data = null;
                    // When creating new opportunity and connecting it to Motivator
                    if (item?.searchText) {
                        const { saveOpportunity } =
                            await this.actions.opportunity.saveOpportunity({
                                data: {
                                    name: item?.searchText,
                                    type: "MOTIVATOR",
                                    targetCustomerUser: {
                                        connect: {
                                            id: this.opportunity
                                                ?.targetCustomerUser?.id,
                                        },
                                    },
                                    venture: {
                                        connect: {
                                            id: this.$route.params.ventureId,
                                        },
                                    },
                                    actionStep: {
                                        create: {
                                            name: upperFirst(item?.searchText),
                                            type: "MOTIVATOR",
                                        },
                                    },
                                },
                            });
                        data = await this.actions.saveFormData({
                            mutation: "saveOpportunityLink",
                            params: {
                                parent: {
                                    connect: { id: saveOpportunity?.id },
                                },
                                child: {
                                    connect: { id: this.opportunity?.id },
                                },
                            },
                        });
                        await this.actions.opportunity.saveOpportunity({
                            where: { id: saveOpportunity?.id },
                            data: {
                                childOpportunityLinks: {
                                    connect: [{ id: data?.id }],
                                },
                            },
                        });
                        params.data.parentOpportunityLinks.connect.push({
                            id: data?.id,
                        });
                        targetCustomerUserOpportunities.push({
                            id: data?.parent?.id,
                        });
                    } else {
                        // When connecting the existing opportunities to Motivator
                        for (
                            let i = 0;
                            i < this.selectedOpportunities?.length;
                            i++
                        ) {
                            data = await this.actions.saveFormData({
                                mutation: "saveOpportunityLink",
                                params: {
                                    parent: {
                                        connect: {
                                            id: this.selectedOpportunities[i]
                                                ?.id,
                                        },
                                    },
                                    child: {
                                        connect: { id: this.opportunity?.id },
                                    },
                                },
                            });
                            await this.actions.opportunity.saveOpportunity({
                                where: {
                                    id: this.selectedOpportunities[i]?.id,
                                },
                                data: {
                                    childOpportunityLinks: {
                                        connect: [{ id: data?.id }],
                                    },
                                },
                            });
                            params.data.parentOpportunityLinks.connect.push({
                                id: data?.id,
                            });
                        }
                    }
                } else {
                    let data = null;
                    if (item?.searchText) {
                        const { saveOpportunity } =
                            await this.actions.opportunity.saveOpportunity({
                                data: {
                                    name: item?.searchText,
                                    type: "SOLUTION",
                                    targetCustomerUser: {
                                        connect: {
                                            id: this.opportunity
                                                ?.targetCustomerUser?.id,
                                        },
                                    },
                                    venture: {
                                        connect: {
                                            id: this.$route.params.ventureId,
                                        },
                                    },
                                    actionStep: {
                                        create: {
                                            name: upperFirst(item?.searchText),
                                            type: "SOLUTION",
                                        },
                                    },
                                },
                            });
                        data = await this.actions.saveFormData({
                            mutation: "saveOpportunityLink",
                            params: {
                                child: { connect: { id: saveOpportunity?.id } },
                                parent: {
                                    connect: { id: this.opportunity?.id },
                                },
                            },
                        });
                        params.data.childOpportunityLinks.connect.push({
                            id: data?.id,
                        });
                        await this.actions.opportunity.saveOpportunity({
                            where: { id: saveOpportunity?.id },
                            data: {
                                parentOpportunityLinks: {
                                    connect: [{ id: data?.id }],
                                },
                            },
                        });
                        targetCustomerUserOpportunities.push({
                            id: data?.child?.id,
                        });
                    } else {
                        for (
                            let i = 0;
                            i < this.selectedOpportunities?.length;
                            i++
                        ) {
                            data = await this.actions.saveFormData({
                                mutation: "saveOpportunityLink",
                                params: {
                                    child: {
                                        connect: {
                                            id: this.selectedOpportunities[i]
                                                ?.id,
                                        },
                                    },
                                    parent: {
                                        connect: { id: this.opportunity?.id },
                                    },
                                },
                            });
                            params.data.childOpportunityLinks.connect.push({
                                id: data?.id,
                            });
                            await this.actions.opportunity.saveOpportunity({
                                where: {
                                    id: this.selectedOpportunities[i]?.id,
                                },
                                data: {
                                    parentOpportunityLinks: {
                                        connect: [{ id: data?.id }],
                                    },
                                },
                            });
                        }
                    }
                }
                console.log(params);
                const { saveOpportunity } =
                    await this.actions.opportunity.saveOpportunity(params);
                await this.actions.venture.saveVenture({
                    where: { id: this.venture?.id },
                    data: {
                        targetCustomerUsers: {
                            update: [
                                {
                                    where: { id: this.targetCustomerUser?.id },
                                    data: {
                                        opportunities: {
                                            connect:
                                                targetCustomerUserOpportunities,
                                        },
                                    },
                                },
                            ],
                        },
                        opportunities: {
                            connect: targetCustomerUserOpportunities,
                        },
                    },
                });
                await this.fetchVenture();
                this.targetCustomerUser =
                    this.venture.targetCustomerUsers?.find(
                        (u) => u.id === this.targetCustomerUser?.id
                    );
                this.opportunity = saveOpportunity;
                this.getPercentageOfOpportunity();
                this.selectedOpportunities = [];
                this.isShowOpportunitySelect = false;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        onSelectOpportunity(opportunity) {
            console.log(opportunity, "opportunity");
            const opportunities = [...this.selectedOpportunities];
            const index = opportunities?.findIndex(
                (o) => o.id === opportunity?.id
            );
            if (index > -1) {
                opportunities?.splice(index, 1);
            } else {
                opportunities.push(opportunity);
            }
            this.selectedOpportunities = opportunities;
        },
        editItem(id, item) {
            console.log(id, item);
            if (item?.header?.includes("Motivators")) {
                const opportunity = item.tableList[id].id?.parent?.id;
                this.$router.push({
                    name: "Venture Opportunity Detail",
                    params: {
                        ventureId: this.$route.params?.ventureId,
                        id: opportunity,
                    },
                });
            } else {
                const opportunity = item.tableList[id].id?.child?.id;
                this.$router.push({
                    name: "Venture Opportunity Detail",
                    params: {
                        ventureId: this.$route.params?.ventureId,
                        id: opportunity,
                    },
                });
            }
        },

        /*
         *
         */
        getPercentageOfOpportunity() {
            const items1 = [],
                items2 = [];
            //
            this.opportunity?.parentOpportunityLinks?.map((o) => {
                const userSurveys = this.surveyData?.filter((s) =>
                    s.answers?.find((a) =>
                        a.answers?.find(
                            (b) => b.metadata?.opportunityId === o?.parent?.id
                        )
                    )
                );
                items1.push({
                    name: o?.parent?.name,
                    desc:
                        this.surveyData?.length > 0
                            ? userSurveys?.length > 0
                                ? `${userSurveys?.length || 0} people (${
                                      !isNaN(
                                          (userSurveys?.length /
                                              this.surveyData?.length) *
                                              100
                                      )
                                          ? (
                                                (userSurveys?.length /
                                                    this.surveyData?.length) *
                                                100
                                            )?.toFixed(2)
                                          : 0
                                  }%)`
                                : `0 people (0%)`
                            : "",
                    id: o,
                });
            });

            //
            this.opportunity?.childOpportunityLinks?.map((o) => {
                const userSurveys = this.surveyData?.filter((s) =>
                    s.answers?.find((a) =>
                        a.answers?.find(
                            (b) => b.metadata?.opportunityId === o?.child?.id
                        )
                    )
                );

                items2.push({
                    name: o?.child?.name,
                    desc:
                        this.surveyData?.length > 0
                            ? userSurveys?.length > 0
                                ? `${userSurveys?.length || 0} people (${
                                      !isNaN(
                                          (userSurveys?.length /
                                              this.surveyData?.length) *
                                              100
                                      )
                                          ? (
                                                (userSurveys?.length /
                                                    this.surveyData?.length) *
                                                100
                                            )?.toFixed(2)
                                          : 0
                                  }%)`
                                : `0 people (0%)`
                            : "",
                    id: o,
                });
            });

            //
            this.detailLinkedItems[0].tableList = items1;
            this.detailLinkedItems[1].tableList = items2;
        },
        async removeItem(id, item) {
            console.log(id, item);
            let params = {
                where: { id: this.opportunity?.id },
                data: {
                    parentOpportunityLinks: {},
                    childOpportunityLinks: {},
                },
            };
            if (item?.header?.includes("Motivators")) {
                const opportunityLink =
                    this.detailLinkedItems[0].tableList[id]?.id;
                params.data.parentOpportunityLinks.disconnect = [
                    { id: opportunityLink?.id },
                ];
            } else {
                const opportunityLink =
                    this.detailLinkedItems[1].tableList[id]?.id;
                params.data.childOpportunityLinks.disconnect = [
                    { id: opportunityLink?.id },
                ];
            }
            console.log(params, "params");
            const { saveOpportunity } =
                await this.actions.opportunity.saveOpportunity(params);
            this.opportunity = saveOpportunity;
            this.getPercentageOfOpportunity();
        },
        async onUpdate(items) {
            console.log(items, "items");
            const interviews = [];
            items.map((item) => interviews?.push({ id: item?.id }));
            const { saveOpportunity } =
                await this.actions.opportunity.saveOpportunity({
                    where: { id: this.opportunity?.id },
                    data: {
                        interviews: {
                            connect: interviews,
                        },
                    },
                });
            this.opportunity = saveOpportunity;
            this.opportunity?.interviews
                ?.filter((o) => !o?.isArchived)
                ?.map((interview) => {
                    this.linkedItems[0]?.tableList?.push({
                        Contact: `${
                            interview?.contact?.user?.firstName || ""
                        } ${interview?.contact?.user?.lastName || ""}`,
                        "Interview Date": moment(
                            interview?.scheduledDateTime
                        ).format("MM/DD/YY"),
                        "Linked Opportunities": `${
                            interview?.opportunities?.filter(
                                (o) => !o?.isArchived
                            )?.length
                        } Opportunities`,
                    });
                });
            this.linkedItems[0].multiSelectDropdown =
                this.interviewsList?.filter(
                    (i) =>
                        !this.opportunity?.interviews?.find(
                            (int) => int?.id === i?.id
                        )
                );
        },
        async onRemoveEconomy(field, index, item) {
            console.log(index, "index", item);
            field.list.splice(index, 1);
            const problems = this.opportunity.problems.filter(
                (p) => p?.economy?.id === item?.id
            );
            const disconnects = [];
            problems?.map((p) => disconnects.push({ id: p?.id }));
            const { saveOpportunity } =
                await this.actions.opportunity.saveOpportunity({
                    where: { id: this.opportunity?.id },
                    data: {
                        problems: {
                            delete: disconnects,
                        },
                    },
                });
            this.opportunity = saveOpportunity;
        },
        async onClickEconomy(field, item) {
            this.isOpenProblems = false;
            field.list.push({
                id: null,
                name: item?.problem,
                desc: `0 people (0%)`,
            });
            const { saveOpportunity } =
                await this.actions.opportunity.saveOpportunity({
                    where: { id: this.opportunity?.id },
                    data: {
                        problems: {
                            create: [
                                {
                                    type: "PROBLEM",
                                    economy: {
                                        connect: { id: item?.id },
                                    },
                                },
                            ],
                        },
                    },
                });
            this.opportunity = saveOpportunity;
            field.list[field.list.length - 1].id =
                this.opportunity?.problems?.[
                    this.opportunity?.problems?.length - 1
                ]?.id;
            console.log(field.list);
        },
        getEconomies(list) {
            console.log(list, "list");
            return this.state.economy.economies?.filter(
                (o) => o?.problem && !list?.find((l) => l?.name === o?.problem)
            );
        },
        async onRemoveOpportunity() {
            await this.actions.opportunity.deleteOpportunity({
                where: { id: this.opportunity?.id },
            });
            this.isOpenRemove = false;
            this.actions.alert.showSuccess({
                message: "Deleted the opportunity successfully!",
            });
            setTimeout(() => this.$router.go(-1), 500);
        },
        onClickMenu(item) {
            // if (item === 'Archive') {
            this.isOpenRemove = true;
            // }
        },
        getTabItems() {
            return [
                {
                    name: "Details",
                    count: null,
                    href: "#",
                    icon: "PencilIcon",
                    current: false,
                },
                {
                    name: "Interviews",
                    count: this.linkedItems[0]?.tableList?.length,
                    href: "#",
                    icon: "ChatAlt2Icon",
                    current: false,
                },
                {
                    name: "Survey Data",
                    count: this.surveys?.length,
                    href: "#",
                    icon: "ChartBarIcon",
                    current: true,
                },
            ];
        },
        onCancel() {
            this.formData.targetCustomer = {
                name: upperFirst(this.opportunity?.targetCustomerUser?.name),
            };
            this.formData.action = {
                ...this.opportunity?.actionStep,
                label: this.opportunity?.actionStep?.name,
            };
            this.formData.type = { name: this.opportunity?.type };
            this.formData.motivation = JSON.parse(
                this.opportunityDetailForm[0].fields[1].options
            ).find((o) => o.value === this.opportunity.desireText);
            this.formData.frequency = this.opportunity?.frequency;
            this.formData.frequencyUnit = this.frequencyOptions.find(
                (o) => o.value === this.opportunity.frequencyUnit
            );
            this.formData.problems = this.opportunity?.problems;
            this.formData.context = this.opportunity?.problemContext;
        },
        async onSave() {
            if (this.isLoading) return false;
            this.isLoading = true;
            try {
                console.log(this.formData, "formData");
                const params = {
                    frequency: parseInt(this.formData?.frequency) || 0,
                    frequencyUnit: this.formData?.frequencyUnit?.value,
                    desireText: this.formData?.motivation?.value,
                    problemContext: this.formData?.context,
                    type: this.formData?.type?.name,
                };
                if (
                    this.formData?.targetCustomer?.id &&
                    this.formData?.targetCustomer?.id !==
                        this.opportunity?.targetCustomerUser?.targetCustomer?.id
                ) {
                    params.targetCustomerUser = {
                        create: {
                            targetCustomer: {
                                connect: {
                                    id: this.formData?.targetCustomer?.id,
                                },
                            },
                        },
                    };
                }
                if (this.formData?.action?.label) {
                    const items = this.opportunity?.name?.split(" ");
                    if (items?.[0]?.includes("#")) {
                        params.name =
                            items[0] +
                            " " +
                            upperFirst(this.formData?.action?.label);
                    }
                }
                if (this.formData?.action?.id) {
                    params.actionStep = {
                        connect: { id: this.formData?.action?.id },
                    };
                } else if (this.formData?.action?.label) {
                    params.actionStep = {
                        create: {
                            name: this.formData?.action?.label,
                            type:
                                this.formData?.type?.name === "SOLUTION"
                                    ? "SOLUTION"
                                    : "ACTION",
                            status: "NEW",
                        },
                    };
                }

                const { saveOpportunity } =
                    await this.actions.opportunity.saveOpportunity({
                        where: { id: this.opportunity?.id },
                        data: params,
                    });
                this.opportunity = saveOpportunity;
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        async fetchVenture() {
            const ventures = await this.actions.venture.getVentures({
                where: { id: this.$route.params?.ventureId },
                getValues: true,
                query: "ventureOpportunities",
            });
            console.log(ventures[0]);
            this.venture = ventures[0];
        },
        async onInitialize() {
            await this.fetchVenture();
            const items = await this.actions.opportunity.getOpportunities({
                where: { id: this.$route.params?.id },
                getValues: true,
            });

            this.opportunity = items[0];
            const problem = [];
            const improvements = [];
            this.opportunity.problems?.map((p) => {
                problem.push(p?.economy?.problem);
                improvements.push(p?.economy?.improvement);
            });
            this.accordion = {
                name: "Opportunity Statement and Solutions Question",
                content: [
                    {
                        title: "Opportunity Statement",
                        items: [
                            `${upperFirst(
                                this.opportunity.targetCustomerUser?.name || ""
                            )} ${
                                convertEnumToRead(
                                    this.opportunity.desireText
                                )?.toLowerCase() || ""
                            } ${this.opportunity.actionStep?.name || ""}${
                                this.opportunity.problems?.length > 0
                                    ? `, but ${problem?.join(", ")}`
                                    : ""
                            } ${
                                this.opportunity.problemContext
                                    ? ` because ${
                                          this.opportunity.problemContext || ""
                                      }`
                                    : ""
                            }.`,
                        ],
                    },
                    {
                        title: "Solution Question",
                        items: [
                            `Can we make it significantly ${improvements?.join(
                                " and "
                            )} for ${this.opportunity.targetCustomerUser?.name?.toLowerCase()} to ${
                                this.opportunity.actionStep?.name || ""
                            }?`,
                        ],
                    },
                ],
            };
            console.log(this.opportunity, "opportunity");
            const { getSurveySummary: summary } =
                await this.actions.opportunity.getSurveySummary({
                    opportunityId: this.opportunity?.id,
                });
            console.log(this.opportunity);
            this.targetCustomerUser = this.venture?.targetCustomerUsers?.find(
                (t) =>
                    t?.opportunities?.find(
                        (o) => o?.id === this.opportunity?.id
                    )
            );
            const interviews = [];
            this.targetCustomerUser?.contacts
                ?.filter((o) => !o?.isArchived)
                ?.map((c) => {
                    c.interviews
                        ?.filter((o) => !o?.isArchived)
                        ?.map((int) => {
                            const item = interviews?.find(
                                (interview) => interview?.id === int.id
                            );
                            if (!item) {
                                int.name = `${
                                    int?.contact?.user?.firstName || ""
                                }${
                                    int?.contact?.user?.lastName
                                        ? ` ${int?.contact?.user?.lastName}`
                                        : ""
                                } - ${int?.name}`;
                                interviews?.push(int);
                            }
                        });
                });
            this.interviewsList = interviews;
            this.linkedItems[0].multiSelectDropdown =
                this.interviewsList?.filter(
                    (i) =>
                        !this.opportunity?.interviews?.find(
                            (int) => int?.id === i?.id
                        )
                );
            this.opportunity?.interviews?.map((interview) => {
                this.linkedItems[0]?.tableList?.push({
                    Contact: `${interview?.contact?.user?.firstName || ""} ${
                        interview?.contact?.user?.lastName || ""
                    }`,
                    "Interview Date": moment(
                        interview?.scheduledDateTime
                    ).format("MM/DD/YY"),
                    "Linked Opportunities": `${
                        interview?.opportunities?.filter((o) => !o?.isArchived)
                            ?.length
                    } Opportunities`,
                });
            });

            this.surveyData = await this.actions.userSurvey.getUserSurveys({
                where: {
                    opportunity: { id: this.$route.params?.id },
                    isArchived_not: true,
                },
                getValues: true,
            });
            console.log(this.surveyData, "surveyData");
            this.opportunityData.tables.body = [
                ["Magnitude", summary?.magnitude],
                ["Desire", summary?.desire],
                ["Experience", summary?.experience],
                ["Action Done", summary?.activityDone + "%"],
                [
                    "Frequency",
                    this.opportunity?.frequency ? summary?.frequency : "N/A",
                ],
                ["Out of Market", summary?.outOfMarket + "%"],
                ["In Market", summary?.inMarket + "%"],
                ["Adjusted Market", summary?.adjustedMarket],
                ["Early Adopters", summary?.earlyAdopters],
                ["Early Adopter Size", summary?.earlyAdopterSize],
            ];
            this.detailLinkedItems[0].tableList = [];
            this.detailLinkedItems[1].tableList = [];
            this.opportunityData.navigationGraph.experience.value =
                summary?.graphs?.[0]?.data?.[0]?.value;
            this.opportunityData.navigationGraph.magnitude.percent =
                summary?.graphs?.[0]?.data?.[1]?.value;
            this.opportunityData.navigationGraph.desire.value =
                summary?.graphs?.[0]?.data?.[2]?.value;
            this.opportunityData.navigationGraph.outOfMarket.percent =
                summary?.graphs?.[1]?.data?.[0]?.value;
            this.opportunityData.navigationGraph.inMarket.percent =
                summary?.graphs?.[1]?.data?.[1]?.value;
            this.opportunityData.navigationGraph.earlyAdopter.percent =
                summary?.graphs?.[1]?.data?.[2]?.value;
            this.opportunityData.demographics[0].content[0] = {
                ...this.opportunityData.demographics[0].content[0],
                ...summary.graphs?.[1]?.data?.[0]?.counts?.[0],
                count: summary.outOfMarketCount,
            };
            this.opportunityData.demographics[0].content[1] = {
                ...this.opportunityData.demographics[0].content[1],
                ...summary.graphs?.[1]?.data?.[1]?.counts?.[0],
                count: summary.lowMarketCount,
            };
            this.opportunityData.demographics[0].content[2] = {
                ...this.opportunityData.demographics[0].content[2],
                ...summary.graphs?.[1]?.data?.[2]?.counts?.[0],
                count: summary.midMarketCount,
            };
            this.opportunityData.demographics[0].content[3] = {
                ...this.opportunityData.demographics[0].content[3],
                ...summary.graphs?.[1]?.data?.[2]?.counts?.[1],
                count: summary.hiMarketCount,
            };
            console.log(this.opportunityData, "opportunityData", summary);
            this.getPercentageOfOpportunity();
            const problems = [];
            const groups = groupBy(this.opportunity.problems, "economy.id");
            Object.values(groups)?.map((g) => {
                const userSurveys = this.surveyData?.filter((s) =>
                    s.answers?.find((a) =>
                        a.answers?.find(
                            (b) =>
                                b.answer?.toLowerCase() ===
                                g?.[0]?.economy?.problem
                        )
                    )
                );
                const contacts = [];
                userSurveys?.map((u) => {
                    if (!contacts?.find((c) => c === u?.profile?.id)) {
                        contacts.push(u?.profile?.id);
                    }
                });
                problems.push({
                    id: g?.[0]?.economy?.id,
                    name: g?.[0]?.economy?.problem,
                    desc:
                        this.surveyData?.length > 0
                            ? contacts?.length > 0
                                ? `${contacts?.length} people (${
                                      !isNaN(
                                          (contacts?.length /
                                              this.surveyData?.length) *
                                              100
                                      )
                                          ? (
                                                (contacts?.length /
                                                    this.surveyData?.length) *
                                                100
                                            )?.toFixed(2)
                                          : 0
                                  }%)`
                                : `0 people (0%)`
                            : "",
                });
            });
            await this.actions.economy.getEconomies();
            this.opportunityDetailForm[0].fields[5].list = problems;
            this.formData.targetCustomer = {
                name: upperFirst(
                    this.opportunity?.targetCustomerUser?.name || ""
                ),
            };
            this.formData.action = {
                ...this.opportunity?.actionStep,
                label: this.opportunity?.actionStep?.name,
            };
            this.formData.type = { name: this.opportunity?.type };
            this.formData.motivation = JSON.parse(
                this.opportunityDetailForm[0].fields[1].options
            ).find((o) => o.value === this.opportunity.desireText);
            this.formData.frequency = this.opportunity?.frequency;
            this.formData.frequencyUnit = this.frequencyOptions.find(
                (o) => o.value === this.opportunity.frequencyUnit
            );
            this.formData.problems = this.opportunity?.problems;
            this.formData.context = this.opportunity?.problemContext;
            this.surveyData?.map((res) => {
                this.surveysData.push(res);
                this.surveys?.push({
                    id: res?.id,
                    Contact: `${res?.user?.firstName || ""} ${
                        res?.user?.lastName || ""
                    }`,
                    Magnitude: res?.magnitude || 0,
                    "Job Done": res?.isActionDone ? "Yes" : "No",
                    Frequency:
                        res?.isActionDone &&
                        res?.frequency &&
                        this.opportunity?.frequencyUnit
                            ? `${res?.frequency || ""}/${
                                  this.opportunity?.frequencyUnit || ""
                              }`
                            : "",
                    Desire: res.desire,
                    Experience: res?.experience || "0",
                    Date: moment(res?.createdAt).format("MM/DD"),
                });
            });
            this.headers = [
                { label: "Contact", isSort: true, isAscending: true },
                { label: "Magnitude", isSort: true, isAscending: true },
                { label: "Job Done", isSort: true, isAscending: true },
                { label: "Frequency", isSort: true, isAscending: true },
                { label: "Desire", isSort: true, isAscending: true },
                { label: "Experience", isSort: true, isAscending: true },
                { label: "Date", isSort: true, isAscending: true },
            ];
            this.surveys = this.surveys.sort((a, b) =>
                a?.Magnitude > b?.Magnitude ? -1 : 1
            );
            if (this.state.actionStep.actionSteps?.length === 0)
                await this.actions.actionStep.getActionSteps({ all: true });
            const actionSteps = [];
            this.state.actionStep.actionSteps
                ?.filter((a) => a?.status === "APPROVED")
                .map((o) => actionSteps?.push({ ...o, label: o?.name }));
            this.opportunityDetailForm[0].fields[2].options =
                JSON.stringify(actionSteps);

            // if (Object.values(this.state.targetCustomer.targetCustomers)?.length === 0) {
            //   await this.actions.targetCustomer.getTargetCustomers({ all: true });
            // }
            const data = [];
            this.venture.targetCustomerUsers
                ?.filter((t) => !t?.isArchived)
                .map((t) => data.push({ ...t.targetCustomer, name: t.name }));
            this.opportunityDetailForm[0].fields[0].options =
                JSON.stringify(data);
            console.log(this.opportunityDetailForm, "opportunityDetailForm");
        },
    },
    async created() {
        this.isPageLoading = true;
        try {
            this.selectedTab = this.getTabItems()[0];
            await this.onInitialize();
        } catch (e) {
            console.log(e);
        } finally {
            this.isPageLoading = false;
        }
    },
};
</script>

<style scoped></style>
